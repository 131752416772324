<template>
  <div class="home">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ name: 'Home' }">{{
        app_name
      }}</el-breadcrumb-item>
      <el-breadcrumb-item>{{$t("api.api_doc")}}</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-block">
      <div class="page-block-header">
        <div class="content-title">
          <p>{{$t("api.api_doc")}}</p>
        </div>
      </div>
    </div>
    <div class="page-content-fill">
      <div id="swagger-doc"></div>
    </div>
  </div>
</template>

<script>
import SwaggerUI from "swagger-ui";
import apiURL from "@/data/api";
// @ is an alias to /src
import { mapState, mapActions } from "vuex";
export default {
  name: "ApiDoc",
  components: {},
  data() {
    return {
      jsonApi: {},
      sui: null,
    };
  },
  methods: { ...mapActions(["switchDefaultPage"]) },
  beforeMount() {
    this.switchDefaultPage("/dashboard/api-doc");
  },
  computed: {
    ...mapState({
      app_name: (state) => state.app_name,
    }),
  },
  async mounted() {
    await this.$get_api_direct(apiURL.api.openapi, null, {}, {}).then((res) => {
      this.jsonApi = res;

      res.servers = [
        {
          url: process.env.VUE_APP_API_BASE_URL,
        },
      ];

      this.sui = SwaggerUI({
        dom_id: "#swagger-doc",
        spec: this.jsonApi,
      });
      return true;
    });
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/main";
</style>

<style lang="scss" >
@import "~swagger-ui/dist/swagger-ui.css";
#api-token-frame {
  font-family: monospace !important;
}
</style>

